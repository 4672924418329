import jq from "jquery";

export function initAutoLogEvent($container) {
    $container.find("[data-log-event]").each((index, element) => {
        const $element = jq(element);
        const options = $element.data('log-event');

        logEvent(options.name, options.entity);
    });
}

export function logEvent(name, entity) {
    console.log("log event", {name, entity});

    return jq.ajax({
        url: '/api/events/event',
        type: 'POST',
        data: {
            name: name,
            entity: entity,
            platform: 'web',
        },
    });
}